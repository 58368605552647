body {
  margin: 0;
}
body * {
  font-weight: normal;
  font-family: "OPS Placard", sans-serif;
  text-wrap: pretty;
}

a {
  text-decoration: none;
  color: inherit;
}
a:active {
  color: inherit;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

@font-face {
  font-family: "OPS Placard";
  font-style: normal;
  src: url("./assets/fonts/opsplacard-regular_RW.ttf");
}
@font-face {
  font-family: "OPS Placard";
  font-style: italic;
  src: url("./assets/fonts/opsplacard-italic.ttf");
}

